import { compile } from 'html-to-text';

const compiledConvert = compile({
    limits: { maxInputLength: undefined },
    selectors: [
        {
            selector: '*',
            options: {
                ignoreHref: true,
                linkBrackets: false,
            },
        },
        {
            selector: 'img',
            format: 'skip',
        },
    ],
});

export default function getTextContent(html: string | undefined) {
    if (!html) {
        return '';
    }

    return compiledConvert(html).replace(/\[(data:image\/.+;base64,.*)\]/gis, '');
}
