import HashCode from 'hash/HashCode.ts';
import { ForwardedRef, forwardRef, MutableRefObject, useEffect, useRef } from 'react-dom';
import { Editor, EditorEvent, TinyMCE } from 'static/tinymce/tinymce.d.ts';
import SignalLike from 'api/types/SignalLike.ts';
import MaybeSignal, { isReadOnlySignal, isSignal } from 'api/types/MaybeSignal.ts';
import { asset, IS_BROWSER } from '$fresh/runtime.ts';
import getTextContent from 'api/dom/getTextContent.ts';

export interface RichTextEditorProps {
    name?: string | number;
    value?: MaybeSignal<string>;
    content?: SignalLike<[string, string]>;
    placeholder?: string;
    width?: number;
    height?: number;
    inline?: boolean | true | false | 'true' | 'false';
    onInput?: (e: Event) => void;
}

const RichTextEditor = forwardRef(
    function (props: RichTextEditorProps, ref?: ForwardedRef<HTMLTextAreaElement>) {
        props.inline ??= false;

        if (props.inline == 'true' || props.inline == 'false') {
            props.inline = props.inline === 'true';
        }

        ref ??= useRef<HTMLTextAreaElement>(null);

        const name = typeof props?.name == 'string' ? props.name : ('RichTextEditor_' +
            (typeof props?.name == 'number' ? props.name : HashCode.bind(props)())
                .toString());

        if (IS_BROWSER) {
            useEffect(() => {
                if (!(ref as MutableRefObject<HTMLTextAreaElement>).current) return;

                const whentiny = () => {
                    (globalThis as typeof globalThis & { tinymce?: TinyMCE }).tinymce?.init({
                        selector: `#${name}`,
                        referrer_policy: 'origin',
                        content_css_cors: true,
                        width: props.width ?? '100%',
                        height: props.height ?? '100%',
                        min_height: 300,
                        xss_sanitization: false,

                        element_format: 'xhtml',
                        forced_root_block: 'div',
                        remove_linebreaks: false,
                        remove_redundant_brs: false,
                        cleanup_on_startup: false,
                        cleanup: false,
                        verify_html: false,
                        apply_source_formatting: false,
                        fix_nesting: false,
                        fix_table_elements: false,
                        fix_list_elements: false,
                        fix_content_duplication: false,
                        preformatted: false,

                        resize: !!props.inline,
                        placeholder: props.placeholder ?? 'Type here...',
                        branding: false,
                        statusbar: false,
                        promotion: false,
                        menubar: false,
                        inline: !!props.inline,
                        paste_data_images: true,
                        default_link_target: '_blank',

                        plugins: [
                            'advlist',
                            'autolink',
                            'autoresize',
                            'lists',
                            'link',
                            'image',
                            'charmap',
                            'anchor',
                            'searchreplace',
                            'visualblocks',
                            'code',
                            'fullscreen',
                            'insertdatetime',
                            'media',
                            'table',
                            'preview',
                            'help',
                            'wordcount',
                            'quickbars',
                        ].join(' '),

                        toolbar: [
                            [
                                'undo',
                                'redo',
                            ],
                            [
                                'blocks',
                            ],
                            [
                                'bold',
                                'italic',
                                'forecolor',
                            ],
                            [
                                'alignleft',
                                'aligncenter',
                                'alignright',
                                'alignjustify',
                            ],
                            [
                                'bullist',
                                'numlist',
                                'outdent',
                                'indent',
                            ],
                            [
                                'quicktable',
                            ],
                            [
                                'code',
                                'removeformat',
                                'help',
                            ],
                        ].map((x) => {
                            x.push('|');
                            return x;
                        }).flat().join(' ').slice(0, -' |'.length),

                        quickbars_insert_toolbar: false,
                        quickbars_selection_toolbar: 'quicklink',
                        quickbars_image_toolbar: false,

                        setup: function (editor: Editor) {
                            editor.on('init', function (e) {
                                editor.setContent(
                                    props.content?.value?.[1] ?? (props.value as SignalLike<string>).value ?? '',
                                );

                                const c = editor.getContentAreaContainer();

                                if (props.inline) {
                                    [
                                        'absolute',
                                        'borderblock',
                                        'bg-transparent',
                                        'text-gray-900',
                                        'focus:ring-0',
                                        'sm:text-sm',
                                        'sm:leading-6',
                                        'rounded-md',
                                        'shadow-sm',
                                        'ring-1',
                                        'ring-inset',
                                        'ring-gray-300',
                                        'focus-within:ring-2',
                                        'focus-within:ring-inset',
                                        'focus-within:ring-dspace',
                                        'sm:max-w-md',
                                    ].forEach((x) => c.classList.add(`!${x}`));
                                }
                            });

                            const oncontent = (e: Event | EditorEvent<unknown>) => {
                                const c = editor.getContent();
                                const conv = getTextContent(c);

                                if (props.content) {
                                    props.content.value = [conv, c];
                                }

                                if (isSignal(props.value) && !isReadOnlySignal(props.value)) {
                                    props.value.value = c;
                                }

                                return c;
                            };

                            [
                                'ExecCommand',
                                'FormatApply',
                                'FormatRemove',
                                'NodeChange',
                                'input',
                                'Change',
                                'Redo',
                                'Undo',
                            ].forEach((x) =>
                                editor.on(
                                    x,
                                    x == 'ExecCommand'
                                        ? (
                                            e: EditorEvent<{ command: string; ui?: boolean; value?: unknown }>,
                                        ) => {
                                            oncontent(e);

                                            if (
                                                [
                                                    'Bold',
                                                    'Italic',
                                                    'Underline',
                                                    'Strikethrough',
                                                    'Superscript',
                                                    'Subscript',
                                                    'Cut',
                                                    'Paste',
                                                    'ForeColor',
                                                    'BackColor',
                                                    'JustifyLeft',
                                                    'JustifyCenter',
                                                    'JustifyRight',
                                                    'JustifyFull',
                                                    'FontName',
                                                    'FontSize',
                                                    'FormatBlock',
                                                    'RemoveFormat',
                                                    'Indent',
                                                    'Outdent',
                                                    'CreateLink',
                                                    'Unlink',
                                                    'InsertHorizontalRule',
                                                    'InsertParagraph',
                                                    'InsertText',
                                                    'InsertHTML',
                                                    'InsertImage',
                                                    'Delete',
                                                    'ForwardDelete',
                                                    'Redo',
                                                    'Undo',
                                                    'HiliteColor',
                                                    'InsertLineBreak',
                                                    'InsertNewBlockAfter',
                                                    'InsertNewBlockBefore',
                                                    'JustifyNone',
                                                    'Lang',
                                                    'LineHeight',
                                                    'mceApplyTextcolor',
                                                    'mceBlockQuote',
                                                    'mceCleanup',
                                                    'mceInsertClipboardContent',
                                                    'mceInsertContent',
                                                    'mceInsertLink',
                                                    'mceInsertNewLine',
                                                    'mceNewDocument',
                                                    'mceRemoveNode',
                                                    'mceRemoveTextcolor',
                                                    'mceReplaceContent',
                                                    'mceSetContent',
                                                    'mceToggleFormat',
                                                    'mceTableSizingMode',
                                                    'mceTableApplyCellStyle',
                                                    'mceTableSplitCells',
                                                    'mceTableMergeCells',
                                                    'mceTableInsertRowBefore',
                                                    'mceTableInsertRowAfter',
                                                    'mceTableInsertColBefore',
                                                    'mceTableInsertColAfter',
                                                    'mceTableDeleteCol',
                                                    'mceTableDeleteRow',
                                                    'mceTableCutRow',
                                                    'mceTableCutCol',
                                                    'mceTablePasteRowBefore',
                                                    'mceTablePasteRowAfter',
                                                    'mceTablePasteColBefore',
                                                    'mceTablePasteColAfter',
                                                    'mceTableDelete',
                                                    'mceTableCellToggleClass',
                                                    'mceTableTogleCaption',
                                                    'mceInserTable',
                                                    'mceTableRowType',
                                                    'mceTableColType',
                                                    'mceTableCellType',
                                                    'ApplyOrderedListStyle',
                                                    'ApplyUnorderedListStyle',
                                                    'mceAdvancedTableSort',
                                                    'mceSortTableAdvanced',
                                                    'mceSortTableByColumnAsc',
                                                    'mceSortTableByColumnDesc',
                                                    'mceSortTableByRowAsc',
                                                    'mceSortTableByRowDesc',
                                                    'mceTableToggleSeries',
                                                    'mceLowerCase',
                                                    'mceTitleCase',
                                                    'mceUpperCase',
                                                    'tc-delete-conversation-at-cursor',
                                                    'tc-try-delete-all-conversations',
                                                    'mceDirectionLTR',
                                                    'mceDirectionRTL',
                                                    'mceImageRotateRight',
                                                    'mceImageRotateLeft',
                                                    'mceImageFlipVertical',
                                                    'mceImageFlipHorizontal',
                                                    'mceInsertFootnote',
                                                    'mceUpdateFootnotes',
                                                    'mcePaintFormats',
                                                    'mceInsertDate',
                                                    'mceInsertTime',
                                                    'mceLink',
                                                    'InsertDefinitionList',
                                                    'InsertOrderedList',
                                                    'InsertUnorderedList',
                                                    'mceListUpdate',
                                                    'mceListProps',
                                                    'RemoveList',
                                                    'mceNonBreaking',
                                                    'mcePageBreak',
                                                    'mceInsertClipboardContent',
                                                    'mceCancel',
                                                    'mceSave',
                                                    'mceInsertToc',
                                                    'mceUpdateToc',
                                                    'mceInsertTemplate',
                                                ].includes(e.command)
                                            ) {
                                                props.onInput?.(new Event('input'));
                                            }
                                        }
                                        : (e: Event) => {
                                            oncontent(e);
                                            props.onInput?.(new InputEvent('input', { data: oncontent(e) }));
                                            (ref as MutableRefObject<HTMLTextAreaElement>).current?.form
                                                ?.dispatchEvent(
                                                    new Event('input'),
                                                );
                                        },
                                )
                            );

                            editor.on('LoadContent', () =>
                                editor.on('setContent', (e: Event) => {
                                    props.onInput?.(new InputEvent('input', { data: oncontent(e) }));
                                }));
                        },
                    });
                };

                if ((globalThis as typeof globalThis & { tinymce?: TinyMCE }).tinymce) {
                    whentiny();
                } else {
                    const s = document.createElement('script');
                    s.src = asset('/tinymce/tinymce.min.js');
                    s.type = 'module';
                    s.referrerPolicy = 'origin';
                    s.addEventListener('load', whentiny);
                    document.head.appendChild(s);
                }

                return () => (globalThis as typeof globalThis & { tinymce?: TinyMCE }).tinymce?.remove(`#${name}`);
            }, [(ref as MutableRefObject<HTMLTextAreaElement>).current]);
        }

        return (
            <textarea
                ref={ref}
                id={name}
                className='w-full h-full  min-h-[400px]'
            >
            </textarea>
        );
    },
);

export default RichTextEditor;
